module.exports = {
    config: {
        merchantId: 1,
        merchantShopName: "Rêve et Créations",
        merchantDomain: "https://reveetcreations.com/",
        defaultMetaImage: "assets/images/meta-shop-picture.png",
        enabledLanguagues: ['fr'],
        defaultLanguage: 'fr',
        enabledCurrencies: ["EUR"],
        defaultCurrency: "EUR",
        enabledDeliveryCountry: ['fr'],
        nbProductsByPage: 12,
        shopEmail: "contact@reveetcreations.com",
        shopPhone: "06 27 33 40 71",
        stripeCountryAccount: 'FR',
        shopAddress: "Valbonne",
        shopPostalCity: "06560",
        shopCountry: "France",
        shopCountryCode: "FR",
        shopSlogan: {
            "fr": "",
        },
        shopMapsUrl: "",
        socialMedias: {
            facebook: "https://www.facebook.com/reve.et.creations/",
            instagram: "https://www.instagram.com/reve.et.creations/"
        },
        footerLink: [
            {
                "name": "Mon Histoire",
                "url": "/about-us"
            },
            {
                "name": "Boutique",
                "url": "/catalog"
            },
            {
                "name": "Livraison",
                "url": "/delivery"
            },
            {
                "name": "FOOTER_TERMS",
                "url": "/terms-of-sales"
            },
            {
                "name": "FOOTER_LNOTICE",
                "url": "/legal-notice"
            },

        ],
        menu: [
            {
                "name": "NAV_HOME",
                "path": "home",
                "submenu": []
            },
            {
                "name": "Mon Histoire",
                "path": "about-us",
                "submenu": []
            },
            {
                "name": "Boutique",
                "path": "catalog",
                "submenu": []
            },
            {
                "name": "Contact",
                "path": "contact",
                "submenu": []
            }
        ],
        mondialRelayCode: "CC220ZKI",
        mondialRelayColLivMod: "24R",
        stripeKey: "pk_live_51IDX9sFONvuSNpV2o5KatJP8qQncNXdbw5DfzLuVwEoRk6dyyxdC0U3WasFIWMO25RidY1DF6TO9HncLY1YMyDrl00vpUhDuJU",
        stripeAccount: "acct_1IiICOKiW46jcS6R",
        quoteDownloadEnabled: false,
        reviewEnabled: true,
        enabledB2B: false,
    }
}
